import { ReactNode } from "react";
import { Theme, createTheme, ThemeProvider } from "@mui/material/styles";
import { blue, error, gray, success } from "common/styling/colors";

const theme: Theme = createTheme({
  color: {
    defaultBackground: "#f8f8fb",
    defaultBorder: "#E6E7E9",
    disabledInputFieldDark: "#545454",
    disabledInputFieldLight: "#808080",
    white: "#FFFFFF",
    black: "#000000",
    veryDarkBlue: "#181819",
    darkBlue: "#0C2945",
    darkGrey: "#3F4857",
    mediumDarkGrey: "#8692A4",
    grey: "#C2CCD9",
    grey100: "#F1F3F4",
    grey600: "#80868B",
    grey700: "#5F6368",
    lightGrey: "#E6E9F1",
    veryLightGrey: "#F9FAFC",
    lightBlue: "#47A1F8",
    lightBlueTint: "#F3F9FD",
    aliceBlue: "#F5FBFE",
    turquoise: "#00B7C4",
    turquoiseTint: "#E5F8F9",
    purple: "#7056E1",
    purpleTint: "#ECE8FD",
    yellow: "#FDBB13",
    yellowTint: "#FEF3D8",
    blue: "#186EF0",
    blueTint: "#E2EDFF",
    alertRed: "FF2626",
    red: "#FF5F65",
    redTint: "#FFECED",
    green: "#64C67C",
    greenTint: "#E3FBE9",
    darkGreen: "#3D7A4C"
  },
  typography: {
    fontFamily: [
      "Inter",
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "sans-serif"
    ].join(","),
    button: {
      textTransform: "none",
      fontWeight: "bold"
    },
    h1: {
      fontSize: "30px",
      lineHeight: "38px",
      fontWeight: 700
    },
    h2: {
      fontSize: "24px",
      lineHeight: "32px",
      fontWeight: 700
    },
    h3: {
      fontSize: "20px",
      lineHeight: "30px",
      fontWeight: 700
    },
    h4: {
      fontSize: "18px",
      lineHeight: "28px",
      fontWeight: 700
    },
    h5: {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 700
    },
    h6: {
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: 700
    },
    body1: {
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: 400
    },
    body2: {
      fontSize: "12px",
      lineHeight: "18px",
      fontWeight: 400
    }
  },
  font: {
    size: {
      extraSmall: "14px",
      small: "16px",
      medium: "18px",
      large: "20px",
      extraLarge: "24px"
    },
    family: [
      "Inter",
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "sans-serif"
    ].join(",")
  },
  // MUI pallete colors
  // https://mui.com/material-ui/customization/theming/
  // We might want to start moving little by little the color properties to the MUI pallete system.

  // TBD assign blue[50] to a variable in the palette, then replace instances of it
  palette: {
    primary: {
      main: blue[700],
      light: blue[600],
      contrastText: "#ffffff"
    },
    secondary: {
      main: gray[600],
      light: blue[300],
      contrastText: "#3F4857"
    },
    error: {
      main: error[600],
      contrastText: "#ffffff"
    },
    text: {
      primary: gray[900],
      secondary: gray[600]
    },
    warning: {
      main: "#FDBB13"
    },
    success: {
      light: success[100],
      main: success[700],
      dark: "#5D8621"
    },
    background: {
      default: "#F8F8FB"
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "4px"
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: "4px"
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: "4px"
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": { backgroundColor: gray[300] }
        }
      }
    }
  }
});

interface IProps {
  children: ReactNode;
}

const MaterialUITheme = ({ children }: IProps) => {
  return (
    <ThemeProvider theme={theme}>
      {/* <ThemeProvider theme={themeV2}> */}
      {/* theme for v2 - uncomment these lines to use it */}
      {children}
      {/* </ThemeProvider> */}
    </ThemeProvider>
  );
};

export default MaterialUITheme;
