import ReduxTagEnum from "../enums/ReduxTagEnum";
import { apiPAB } from "./AxiosService";
import { PromiseWithKnownReason } from "@reduxjs/toolkit/dist/query/core/buildMiddleware/types";
import { AppDispatch } from "../redux";
import StaffLicensingResponse from "../types/StaffLicensing/StaffLicensingResponse";

async function clearCache(
  queryFulfilled: PromiseWithKnownReason<any, any>,
  dispatch: AppDispatch
) {
  try {
    await queryFulfilled;
    setTimeout(() => {
      dispatch(
        staffLicensingService.util.invalidateTags([ReduxTagEnum.StaffLicensing])
      );
    }, 3000);
  } catch (error) {}
}

const staffLicensingService = apiPAB
  .enhanceEndpoints({
    addTagTypes: [ReduxTagEnum.StaffLicensing]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getStaffEnrollments: builder.query<
        StaffLicensingResponse[],
        {
          id?: string;
        }
      >({
        query: () => {
          return {
            url: `/staff-licensing-enrollment`,
            method: "GET"
          };
        },
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          clearCache(queryFulfilled, dispatch);
        }
      })
    })
  });

export const { useGetStaffEnrollmentsQuery } = staffLicensingService;
