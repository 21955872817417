import Table from "../../components/Table/Table";
import StaffLicensingResponse from "common/types/StaffLicensing/StaffLicensingResponse";
import Routes from "../../routes/Routes";
import {
  DateTimeColumn,
  ProviderEnrollmentProviderNameColumn,
  TextColumn
} from "../../components/Table/helpers/TableColumns";

interface IProps {
  providerEnrollmentData: StaffLicensingResponse[];
}

const ProviderEnrollmentTable = ({ providerEnrollmentData }: IProps) => {
  return (
    <Table
      data={providerEnrollmentData}
      tableContainerStyles={{ margin: "0", width: "100%", overflowX: "scroll" }}
      tableProps={{
        externalLink: Routes.STAFF_DETAILS(":staffId", "profile")
      }}
      tableColumns={[
        ProviderEnrollmentProviderNameColumn,
        TextColumn({
          id: "providerCredentials",
          header: "Credentials",
          accessor: "staff.medical_credentials",
          size: 110
        }),
        TextColumn({
          id: "insuranceName",
          header: "Health Plan",
          accessor: "insurance.name",
          size: 200
        }),
        TextColumn({
          id: "insuranceState",
          header: "State",
          accessor: "state",
          size: 100
        }),
        DateTimeColumn({
          header: "Date",
          accessor: "start_date",
          id: "date",
          format: "iso",
          showRelativeTime: false
        })
      ]}
    />
  );
};

export default ProviderEnrollmentTable;
