import styled from "@emotion/styled";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Typography, useTheme } from "@mui/material";
import {
  EditOutlined,
  EditCalendarOutlined,
  MoreTime,
  SmartToy
} from "@mui/icons-material";

import RolesEnum, {
  ProviderRoles,
  canCallTwilioVideo,
  canEditMemberDetails,
  canScheduleNurses,
  canScheduleProviders,
  canSeeReadings,
  canSeeTasks,
  canStartIntake,
  canSubmitEncounters,
  canUpdateNurseAssignment,
  canUpdateProviderAssignment,
  hasNurseRole
} from "common/enums/RolesEnum";
import { RootState, useAppDispatch } from "common/redux";

import { CustomTooltip, StatusBadge } from "../../../styling/StyleComponents";
import {
  RemoteIqMobileIconBW,
  RemoteIqMobileIconColor
} from "../../../assets/images/icons";
import CopyPatientLinkToClipboard from "../../../styling/CopyPatientLinkToClipboard";
import CallTwillioPatientButton from "../../../components/Button/CallTwilioPatientButton";
import { blue, gray } from "common/styling/colors";
import { useSelector } from "react-redux";
import ErrorComponent from "../../../components/ErrorComponent";
import CarersMenu from "./CarersMenu";
import { formatMTD, getNameOrUsername, isFalsy } from "common/helpers/helpers";
import { DateTime } from "luxon";
import AthenaButton from "../../../components/Button/AthenaChartButton";
import { useCallback, useEffect, useMemo, useState } from "react";
import MemberType from "common/types/MemberType";
import MemberStatusEnum from "common/enums/MemberStatusEnum";
import { Alert_close, Alert_show } from "common/helpers/AlertHelper";
import StyledIconButton from "../../../components/Button/StyledIconButton";
import { RowContainer } from "../StartIntake/StyledComponents";
import { useGetLatestReadingsByMemberQuery } from "common/services/ReadingsService";
import DeviceTrendParam from "common/enums/DeviceTrendParamEnum";
import { useGetEncountersMTD } from "common/hooks/useGetEncountersMTD";
import useGetLastEncounter from "common/hooks/useGetLastEncounter";
import ComplianceBanner from "../Overview/ComplianceBanner";
import { useGetMemberWithUsernameQuery } from "common/services/MemberService";
import { Flexbox } from "../../../styling/NewStyleComponents";
import RecordEncounterModal from "../../../components/Modal/RecordEncounterModal";
import SubmitEncounterContainer from "./SubmitEncounter/SubmitEncounterContainer";
import { useGetAthenaMemberDetailsQuery } from "common/services/MemberRegistrationService";
import Tasks from "../../Tasks/Tasks";
import TaskStatusEnum from "common/enums/TaskStatusEnum";
import StartIntakeButton from "../../PatientData/StartIntakeButton";
import {
  hasDiabetes,
  hasHypertension
} from "common/types/Visits/CarePlanType";
import {
  DiabetesButton,
  HypertensionButton
} from "../../../components/Button/ConditionButtons";
import Routes from "../../../routes/Routes";

const Container = styled.div`
  border-radius: 4px;
  border: 1px solid ${gray[300]};
  background: #ffffff;
  display: flex;
  flex: 1;
  align-items: center;
  z-index: 1000;
  padding: 10px 15px;
  gap: 10px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px;
`;

const PatientLinkContainer = styled.div`
  width: 20px;
  height: 20px;
`;

const StyledRemoteIqBWIcon = styled(RemoteIqMobileIconBW)`
  height: 34px;
  width: 18px;
`;

const StyledRemoteIqColorIcon = styled(RemoteIqMobileIconColor)`
  height: 34px;
  width: 18px;
`;

const StyledRemoteIqIconContainer = styled.div`
  width: 18px;
  height: 34px;
  margin: 0px 5px 0px 0px;
`;

const dateFormat = "MM/dd/yyyy";

const RenderOverviewItem = ({ title, value }) => {
  return (
    <Flexbox
      display={"flex"}
      alignItems={"center"}
      flex={1}
      flexDirection={"row"}
      justifyContent={"space-between"}
      textAlign={"center"}
    >
      <Typography
        variant="caption"
        sx={{ fontWeight: 900, textWrap: "nowrap", mr: "3px" }}
      >
        {title}
      </Typography>
      <Typography variant="body2">{value}</Typography>
    </Flexbox>
  );
};

export function MemberHeader({
  patient,
  isPatientError,
  memberId,
  nurseZoomPhone,
  hideStartEncounter = false
}: Readonly<{
  patient: MemberType;
  isPatientError: unknown;
  memberId: string | undefined;
  nurseZoomPhone?: string;
  hideButtons?: boolean;
  hideStartEncounter?: boolean;
}>) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  const { currentRole, user } = useSelector((state: RootState) => state.auth);

  const [isRecordEncounterModalVisible, setIsRecordEncounterModalVisible] =
    useState<boolean>(false);

  const isEditProviderAllowed = canUpdateProviderAssignment(currentRole);
  const isEditNurseAllowed = canUpdateNurseAssignment(currentRole);

  const provider = patient?.patient_provider;
  const providerName = getNameOrUsername(provider);

  const nurse = patient?.assigned_nurse;
  const nurseName = getNameOrUsername(nurse);

  const athenaId = patient?.patient?.external_accounts?.athena;

  const getMemberTime = () => {
    return DateTime.now().setZone(patient?.patient?.timezone);
  };
  const defaultMemberTime = useMemo(() => getMemberTime(), []);
  const [memberTime, setMemberTime] = useState(defaultMemberTime);

  useEffect(() => {
    const newMemberTime = getMemberTime();
    const interval = setInterval(() => setMemberTime(newMemberTime), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const { data: member } = useGetMemberWithUsernameQuery({
    username: memberId
  });

  const { data: athenaMember } = useGetAthenaMemberDetailsQuery(athenaId, {
    skip: athenaId === undefined
  });

  const startDate = DateTime.local().startOf("month");
  const { data: latestReadings } = useGetLatestReadingsByMemberQuery(
    {
      memberId,
      devices: [
        DeviceTrendParam.BLOOD_PRESSURE,
        DeviceTrendParam.GLUCOSE,
        DeviceTrendParam.OXIMETER,
        DeviceTrendParam.WEIGHT_SCALE
      ]
    },
    { skip: memberId === undefined }
  );

  const latestReadingsKeys = useMemo(() => {
    if (!latestReadings) return [];
    return Object.keys(latestReadings);
  }, [latestReadings]);

  const lastReadingDate = useMemo(() => {
    if (!latestReadings || latestReadingsKeys.length === 0) return null;
    let latestReadingDate;
    latestReadingsKeys.forEach((key) => {
      if (
        !latestReadingDate ||
        (latestReadings[key].measure_timestamp > latestReadingDate &&
          latestReadings[key].measure_timestamp !== null)
      ) {
        latestReadingDate = latestReadings[key].measure_timestamp;
      }
    });
    return DateTime.fromSeconds(latestReadingDate).toFormat(dateFormat);
  }, [latestReadings, latestReadingsKeys]);

  const {
    mtdEncountersSum: encountersMTD,
    isLoaded: encountersMTDIsLoaded,
    error: encountersMTDError
  } = useGetEncountersMTD(memberId, startDate);

  const {
    lastEncounterVisit: lastTNEncounter,
    isLoaded: lastTNEncounterIsLoaded,
    error: lastTNEncounterError
  } = useGetLastEncounter(memberId, RolesEnum.TH_NURSE);

  const {
    lastEncounterVisit: lastNPEncounter,
    isLoaded: lastNPEncounterIsLoaded,
    error: lastNPEncounterError
  } = useGetLastEncounter(memberId, RolesEnum.NURSE_PROVIDER);

  const isOverviewLoaded =
    lastTNEncounterIsLoaded && lastNPEncounterIsLoaded && encountersMTDIsLoaded;

  const overviewError =
    lastTNEncounterError || lastNPEncounterError || encountersMTDError;

  const tnOnClick = useCallback(() => {
    if (!canScheduleNurses(currentRole)) {
      return;
    }
    if (
      patient?.patient.status === MemberStatusEnum.CANCELED ||
      patient?.patient.status === MemberStatusEnum.AUTO_CANCELED
    ) {
      const id = `cancel-warning-${patient?.patient?.patient_id}`;
      Alert_show({
        dispatch,
        id,
        title: "Error",
        content:
          "Patient is canceled. Are you sure you want to schedule an appointment?",
        type: "error",
        size: "small",
        buttons: [
          {
            text: "Yes",
            style: "default",
            onPress: () => {
              Alert_close({ dispatch, id });
              if (
                pathname ===
                `/members/memberId/${patient.patient.patient_id}/new-nurse-appointment`
              ) {
                // do nothing if we are already on the new appointment page
              } else {
                navigate(
                  `/members/memberId/${patient.patient.patient_id}/new-nurse-appointment`
                );
              }
            }
          },
          {
            text: "No",
            style: "cancel",
            onPress: () => {
              Alert_close({ dispatch, id });
            }
          }
        ]
      });
    } else if (
      pathname ===
      `/members/memberId/${patient.patient.patient_id}/new-nurse-appointment`
    ) {
      // do nothing if we are already on the new nurse appointment page
    } else {
      navigate(
        `/members/memberId/${patient.patient.patient_id}/new-nurse-appointment`
      );
    }
  }, [currentRole, dispatch, navigate, patient, pathname]);

  const providerOnClick = useCallback(() => {
    if (!canScheduleProviders(currentRole)) {
      return;
    }
    if (
      pathname ===
      `/members/memberId/${patient.patient.patient_id}/new-provider-appointment`
    ) {
      // do nothing if we are already on the new provider appointment page
    } else {
      navigate(
        `/members/memberId/${patient.patient.patient_id}/new-provider-appointment`
      );
    }
  }, [currentRole, navigate, patient, pathname]);

  return (
    <>
      <RowContainer gap={"10px"} padding={"5px 0px"}>
        {patient && (
          <Container style={{ alignSelf: "stretch" }}>
            <Box
              display={"flex"}
              flex={1}
              flexDirection={"column"}
              sx={{
                justifyContent: "space-evenly"
              }}
            >
              <Box
                display={"flex"}
                flexDirection={"row"}
                gap={"10px"}
                justifyContent={"space-between"}
              >
                <Box display={"flex"} flexDirection={"row"} gap={"10px"}>
                  <Typography
                    data-testid="MemberDetailsHeader-patientName"
                    variant="h3"
                    whiteSpace="normal"
                    alignContent={"center"}
                    textOverflow={"ellipsis"}
                    overflow={"clip"}
                  >
                    {patient && getNameOrUsername(patient.patient)}
                  </Typography>
                  <Box alignContent={"center"}>
                    <StatusBadge
                      style={{ alignContent: "center" }}
                      status={patient?.patient.status}
                      statusReason={patient?.patient.status_reason}
                    />
                  </Box>
                  <Flexbox alignContent={"center"}>
                    {patient?.patient?.is_test && (
                      <CustomTooltip title="Is Test Member" placement="bottom">
                        <div style={{ alignSelf: "center" }}>
                          <StyledIconButton
                            onClick={() => {}}
                            Icon={SmartToy}
                            iconColor={blue[700]}
                            color={"transparent"}
                            size={"small"}
                          />
                        </div>
                      </CustomTooltip>
                    )}
                    {canSeeReadings(currentRole) && (
                      <>
                        {hasDiabetes(patient) && (
                          <div style={{ alignSelf: "center" }}>
                            <DiabetesButton memberId={patient?.patient?.patient_id} />
                          </div>
                        )}
                        {hasHypertension(patient) && (
                          <div style={{ alignSelf: "center" }}>
                            <HypertensionButton memberId={patient?.patient?.patient_id} />
                          </div>
                        )}
                      </>
                    )}
                  </Flexbox>
                </Box>
                <Box alignContent={"center"}>
                  <CarersMenu
                    patient={patient}
                    isEditProviderAllowed={isEditProviderAllowed}
                    isEditNurseAllowed={isEditNurseAllowed}
                    providerName={providerName}
                    nurseName={nurseName}
                    nurseZoomPhone={nurseZoomPhone}
                  />
                </Box>
              </Box>

              <RowContainer gap={"5px"} justifyContent={"space-between"}>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  alignItems={"center"}
                >
                  {patient?.patient_access_summary?.has_logged_in ? (
                    <StyledRemoteIqIconContainer>
                      <CustomTooltip
                        title="Member has logged into CopilotIQ mobile app"
                        placement="bottom"
                      >
                        <StyledRemoteIqColorIcon />
                      </CustomTooltip>
                    </StyledRemoteIqIconContainer>
                  ) : (
                    <StyledRemoteIqIconContainer>
                      <CustomTooltip
                        title="Member has not logged into CopilotIQ mobile app"
                        placement="bottom"
                      >
                        <StyledRemoteIqBWIcon />
                      </CustomTooltip>
                    </StyledRemoteIqIconContainer>
                  )}
                  <Box>
                    {athenaMember?.sex && (
                      <Row>
                        <Typography variant="h6">Sex:</Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          marginTop="1px"
                        >
                          {athenaMember?.sex}
                        </Typography>
                      </Row>
                    )}
                    {patient?.patient?.address?.state && (
                      <Row>
                        <Typography variant="h6">State:</Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          marginTop="1px"
                        >
                          {patient?.patient.address.state}
                        </Typography>
                      </Row>
                    )}
                    {patient?.patient.birthdate && (
                      <Row>
                        <Typography variant="h6">Birth Date:</Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          marginTop="1px"
                        >
                          {patient?.patient.birthdate}
                        </Typography>
                      </Row>
                    )}
                    <Row style={{ minWidth: "190px" }}>
                      <Typography variant="h6">Member Time:</Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        marginTop="1px"
                      >
                        {memberTime?.toFormat("h:mm a ZZZZ")}
                      </Typography>
                    </Row>
                  </Box>
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    gap={"5px"}
                    alignItems={"center"}
                  >
                    <PatientLinkContainer>
                      <CopyPatientLinkToClipboard
                        memberId={memberId}
                        customLink={Routes.MEMBER_DETAILS("")}
                      />
                    </PatientLinkContainer>

                    {athenaId !== undefined && (
                      <AthenaButton athenaId={athenaId} />
                    )}
                  </Box>
                </Box>

                <Box display={"flex"} gap={"10px"} justifyContent={"right"}>
                  {canStartIntake(currentRole) && (
                    <StartIntakeButton
                      key={`startintake_${athenaId}`}
                      athena_id={athenaId}
                      member_id={memberId}
                    />
                  )}
                  {canCallTwilioVideo(currentRole) && (
                    <CallTwillioPatientButton patient={patient} />
                  )}
                  {(canScheduleNurses(currentRole) ||
                    canScheduleProviders(currentRole)) && (
                    <CustomTooltip
                      placement="bottom"
                      padding="0"
                      title={
                        <Flexbox flexDirection="column">
                          <Box
                            padding="16px"
                            sx={{
                              cursor: !canScheduleProviders(currentRole)
                                ? "not-allowed"
                                : "pointer",
                              backgroundColor: !canScheduleProviders(
                                currentRole
                              )
                                ? gray[200]
                                : "white",
                              "&:hover": {
                                backgroundColor: !canScheduleProviders(
                                  currentRole
                                )
                                  ? gray[200]
                                  : blue[50]
                              }
                            }}
                            onClick={providerOnClick}
                          >
                            <Typography
                              variant="body1"
                              color="text.primary"
                              lineHeight="24px"
                            >
                              New Appointment With Provider
                            </Typography>
                          </Box>
                          <Box
                            padding="16px"
                            sx={{
                              cursor: !canScheduleNurses(currentRole)
                                ? "not-allowed"
                                : "pointer",
                              backgroundColor: !canScheduleNurses(currentRole)
                                ? gray[200]
                                : "white",

                              "&:hover": {
                                backgroundColor: !canScheduleNurses(currentRole)
                                  ? gray[200]
                                  : blue[50]
                              }
                            }}
                            onClick={tnOnClick}
                          >
                            <Typography
                              variant="body1"
                              color="text.primary"
                              lineHeight="24px"
                            >
                              New Appointment With Telehealth Nurse
                            </Typography>
                          </Box>
                        </Flexbox>
                      }
                    >
                      {/* We need the div here or the tooltip won't work */}
                      <div>
                        <StyledIconButton
                          Icon={EditCalendarOutlined}
                          iconColor={theme.palette.primary.main}
                          border="square"
                          // this button is not clickable - we need disabled and e.stopPropagation to prevent the button click event from disrupting the
                          // click events of the tooltip for "New Appointment With Provider" and "New Appointment With Telehealth Nurse"
                          sx={{
                            "&:disabled": {
                              backgroundColor: "white"
                            }
                            // backgroundColor: "white !important"
                          }}
                          disabled={true}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        />
                      </div>
                    </CustomTooltip>
                  )}
                  {canEditMemberDetails(currentRole) && (
                    <CustomTooltip
                      placement="bottom"
                      title="Edit Member Details"
                    >
                      <div>
                        <StyledIconButton
                          Icon={EditOutlined}
                          iconColor={theme.palette.primary.main}
                          border="square"
                          onClick={() =>
                            navigate(
                              `/members/memberId/${patient.patient.patient_id}/profile`
                            )
                          }
                        />
                      </div>
                    </CustomTooltip>
                  )}
                </Box>
              </RowContainer>
            </Box>

            <Box></Box>
          </Container>
        )}
        <ErrorComponent error={overviewError} />
        {isPatientError && (
          <ErrorComponent
            error={
              // ENG-3654
              "RemoteIQ member ID does not exist, please check the link is correct and does not contain any extra characters"
            }
          />
        )}

        {canSubmitEncounters(currentRole) && !hideStartEncounter && (
          <Container
            style={{
              alignSelf: "stretch",
              justifyContent: "center",
              maxWidth: "50px",
              flexDirection: "column"
            }}
          >
            <Box flexDirection={"row"}>
              <div>
                {ProviderRoles.includes(currentRole) ? (
                  <SubmitEncounterContainer
                    memberId={patient?.patient?.patient_id}
                    patient={patient}
                  />
                ) : (
                  <StyledIconButton
                    Icon={MoreTime}
                    iconColor={theme.palette.primary.main}
                    border="square"
                    onClick={() => setIsRecordEncounterModalVisible(true)}
                  />
                )}
              </div>
            </Box>
            <Box flexDirection={"row"}>
              <Typography
                variant="h6"
                color="text.primary"
                flexDirection={"row"}
                textAlign={"center"}
              >
                Enter Time
              </Typography>
            </Box>
          </Container>
        )}

        {isOverviewLoaded && (
          <Container style={{ alignSelf: "stretch", maxWidth: "220px" }}>
            <Box flex={1}>
              <RenderOverviewItem
                title={"Time Spent"}
                value={formatMTD(encountersMTD)}
              />
              <RenderOverviewItem
                title={"Last Reading"}
                value={lastReadingDate ?? "N/A"}
              />
              <RenderOverviewItem
                title={"Last TN Encounter"}
                value={
                  lastTNEncounter
                    ? DateTime.fromISO(lastTNEncounter).toFormat(dateFormat)
                    : "N/A"
                }
              />
              <RenderOverviewItem
                title={"Last NP Encounter"}
                value={
                  lastNPEncounter
                    ? DateTime.fromISO(lastNPEncounter).toFormat(dateFormat)
                    : "N/A"
                }
              />
            </Box>
          </Container>
        )}

        {member && (
          <Container
            style={{
              alignSelf: "stretch",
              justifyContent: "center",
              maxWidth: "180px"
            }}
          >
            <ComplianceBanner patient={member} />
          </Container>
        )}

        <RecordEncounterModal
          isOpen={isRecordEncounterModalVisible}
          onRequestClose={() => setIsRecordEncounterModalVisible(false)}
          member={member}
        />
      </RowContainer>
      {canSeeTasks(currentRole) && !hideStartEncounter && (
        <Tasks
          tasksStatuses={[TaskStatusEnum.TODO]}
          componentHeader={
            hasNurseRole([currentRole]) ? "Urgent Alerts" : "To Do Tasks"
          }
          showWhenNoTasks={false}
          noTasksMessage=""
          showResolveButton={true}
          showActionButtons={true}
          tooltipContent="Important and urgent tasks are shown in this module."
          outerContainerMargin="0px"
          memberId={memberId}
          showTaskCount={false}
        />
      )}
    </>
  );
}

export default MemberHeader;
