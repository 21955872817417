import RolesEnum from "common/enums/RolesEnum";
import {
  AllNursesIcon,
  CalendarIcon,
  PlusIcon
} from "../../assets/images/icons";

import {
  ALL_MEMBERS,
  ALL_RECENT_ORDERS,
  ALL_TEAMS,
  AWAITING_PROVIDER,
  CREATE_TEAM,
  INACTIVE_MEMBERS,
  MEMBER_CHART_CALENDARING,
  MEMBER_CHART,
  NURSE_SCHEDULES,
  ORDER_DETAILS,
  PROVIDER_LIST,
  PROVIDER_SCHEDULES,
  STAFF_DETAILS,
  STAFF_MEMBERS,
  TEAM_DETAILS,
  COMMON_ROUTES
} from "../RouteComponents";

import {
  PROVIDER_SCHEDULES_SIDEBAR,
  SIDEBAR_ALL_MEMBERS,
  SIDEBAR_CLINICAL_TEAMS,
  SIDEBAR_CREATE_TEAM,
  SIDEBAR_DASHBOARD,
  SIDEBAR_INACTIVE_MEMBERS,
  SIDEBAR_MIGRATE_MEMBER,
  SIDEBAR_ORDERS,
  SIDEBAR_SECTIONS
} from "../RouteSidebar";
import { TeamTypeEnum } from "common/enums/TeamTypeEnum";
import MigratePatient from "../../pages/MigratePatient/MigratePatient";
import CreateUserForm from "../../pages/CreateUserForm/CreateUserForm";
import OrderDevices from "../../pages/OrderDevices/OrderDevices";
import Routes from "../Routes";

const sidebar = () => {
  const sidebarItems = [
    SIDEBAR_DASHBOARD(RolesEnum.TECHNICAL_SUPPORT),
    {
      sectionName: SIDEBAR_SECTIONS.users,
      items: [
        {
          itemName: "Create User",
          itemLink: Routes.CREATE_USER,
          ItemIcon: PlusIcon
        },
        {
          itemName: "Staff Members",
          itemLink: Routes.STAFF_MEMBERS,
          ItemIcon: AllNursesIcon
        },
        SIDEBAR_CREATE_TEAM,
        SIDEBAR_CLINICAL_TEAMS
      ]
    },
    {
      sectionName: SIDEBAR_SECTIONS.nurses,
      items: [
        {
          itemName: "Nurse Schedules",
          itemLink: Routes.NURSE_SCHEDULES,
          ItemIcon: CalendarIcon
        }
      ]
    }
  ];

  sidebarItems.push({
    sectionName: SIDEBAR_SECTIONS.providers,
    items: [PROVIDER_SCHEDULES_SIDEBAR]
  });

  sidebarItems.push(
    {
      sectionName: SIDEBAR_SECTIONS.members,
      items: [SIDEBAR_ALL_MEMBERS, SIDEBAR_INACTIVE_MEMBERS, SIDEBAR_ORDERS]
    },
    SIDEBAR_MIGRATE_MEMBER
  );
  return sidebarItems;
};

const TECHNICAL_SUPPORT = {
  routes: [
    ...COMMON_ROUTES,
    {
      path: Routes.ROOT,
      components: ALL_MEMBERS({
        tableColumns: [
          { name: "name" },
          { name: "status" },
          { name: "phone" },
          { name: "birthdate" },
          { name: "nurseAssigned" }
        ]
      })
    },
    {
      path: Routes.MIGRATE_MEMBER,
      Components: <MigratePatient />
    },
    {
      path: Routes.STAFF_MEMBERS,
      components: STAFF_MEMBERS
    },
    {
      path: Routes.CREATE_USER,
      components: <CreateUserForm />
    },

    {
      path: Routes.ALL_MEMBERS,
      components: ALL_MEMBERS({})
    },
    {
      path: Routes.AWAITING_PROVIDER,
      components: AWAITING_PROVIDER({
        tableColumns: [
          { name: "name" },
          { name: "phone" },
          { name: "birthdate" },
          { name: "nurseAssigned" }
        ]
      })
    },
    { path: Routes.INACTIVE_MEMBERS, components: INACTIVE_MEMBERS(false) },

    ...MEMBER_CHART(RolesEnum.TECHNICAL_SUPPORT),
    ...MEMBER_CHART_CALENDARING,
    {
      path: Routes.ORDERS,
      components: [<OrderDevices key="OrderDevices" />, ALL_RECENT_ORDERS()]
    },
    {
      path: Routes.DEVICES_BY_MEMBER,
      components: <OrderDevices />
    },
    ORDER_DETAILS(),
    STAFF_DETAILS,
    CREATE_TEAM,
    ALL_TEAMS({
      types: [TeamTypeEnum.TH_NURSES]
    }),
    TEAM_DETAILS,
    NURSE_SCHEDULES,
    {
      path: Routes.PROVIDER_SCHEDULES,
      components: [PROVIDER_SCHEDULES({})]
    },
    {
      path: Routes.PROVIDERS,
      components: [
        PROVIDER_LIST({
          filterByAuthenticatedUser: false
        })
      ]
    },
    TEAM_DETAILS
  ],
  sidebar: sidebar()
};

export default TECHNICAL_SUPPORT;
