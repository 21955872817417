import { Box, Button, styled, Typography } from "@mui/material";
import {
  getNameOrUsername,
  isFalsy,
  prettyStatusString
} from "common/helpers/helpers";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import {
  useGetMemberWithUsernameQuery,
  useGetVisitsQuery
} from "common/services/MemberService";
import {
  getElapsedTime,
  getTimeFormat,
  safeRefetch,
  tickElapsedTime
} from "../pages/Visits/VisitHelper";
import { RootState, useAppDispatch } from "common/redux";
import { useSelector } from "react-redux";
import VisitStatusEnum from "common/enums/Calendaring/Visits/VisitStatusEnum";
import VisitLinkedEntitiesEnum from "common/enums/VisitLinkedEntitiesEnum";
import StorageEnum from "common/enums/StorageEnum";
import StorageHelper from "common/helpers/StorageHelper";
import { useInterval } from "common/hooks/useInterval";
import useGetAuthenticatedUser from "common/hooks/useGetAuthenticatedUser";
import UserLinkedEntitiesEnum from "common/enums/UserLinkedEntitiesEnum";
import UserPrecenseStatusEnum from "common/enums/UserPrecenseStatusEnum";
import { VoiceCall } from "../pages/Visits/ui/Call/VoiceCall/VoiceCall";
import { Flexbox } from "../styling/NewStyleComponents";
import MemberLinkedEntitiesEnum from "common/enums/MemberLinkedEntitiesEnum";
import { useDeleteUserPrecenseStatusMutation } from "common/services/UserPresenceService";

const VisitContainer = styled(Box)<{
  isUserBusy: boolean;
  showSidebar: boolean;
}>`
  position: fixed;
  width: ${({ showSidebar }) =>
    showSidebar ? `-webkit-calc(100% - 175px)` : `-webkit-calc(100% - 55px)`};
  width: ${({ showSidebar }) =>
    showSidebar ? `-moz-calc(100% - 175px)` : `-moz-calc(100% - 55px)`};
  width: ${({ showSidebar }) =>
    showSidebar ? `-calc(100% - 175px)` : `-calc(100% - 55px)`};
  margin: 0px 3px;
  z-index: 100;
  display: flex;
  background-color: ${({ theme }) => theme.palette.primary.main};
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border-radius: 5px;
`;

const WhiteButton = styled(Button)`
  box-shadow:
    0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
`;

const VisitInProgress = () => {
  const dispatch = useAppDispatch();
  const [lastRoute, setLastRoute] = useState<string>(null);
  const [elapsedTime, setElapsedTime] = useState<string>(null);
  const { visitId } = useSelector((state: RootState) => state.visits);
  const { showSidebar } = useSelector((state: RootState) => state.layout);
  const [deleteUserPrecenseStatus] = useDeleteUserPrecenseStatusMutation();

  const navigate = useNavigate();

  const { data: user } = useGetAuthenticatedUser({
    linkedEntities: [UserLinkedEntitiesEnum.PRESENCE]
  });

  const {
    data: visits,
    isLoading,
    isError: isVisitError,
    isSuccess: isVisitLoaded,
    isUninitialized: isVisitUnitialized,
    refetch
  } = useGetVisitsQuery(
    {
      staff_id: user?.user?.user_id,
      status: VisitStatusEnum.IN_PROGRESS,
      linked_entities: [VisitLinkedEntitiesEnum.CARE_FLOW_SUMMARY]
    },
    { skip: user?.user?.user_id === undefined }
  );

  const visit = useMemo(() => {
    if (isFalsy(visits)) return;

    return visits.find(
      (item) =>
        item.status === VisitStatusEnum.IN_PROGRESS && item.care_flow_summary
    );
  }, [visits]);

  const isInCurrentVisit = useMemo(() => {
    return window.location.href.includes(visit?.visit_id);
  }, [visit, window.location.href]);

  useEffect(() => {
    const noQueryParamHref = window.location.href.split("?")[0];

    if (lastRoute === null || noQueryParamHref !== lastRoute) {
      doRefetch();
      setLastRoute(noQueryParamHref);
    }
    const interval = tickElapsedTime(dispatch);

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [window.location.href]);

  const doRefetch = useCallback(() => {
    // Only do this if still in the care flow
    const stillInCareFlow = window.location.href.includes(visitId);
    if (stillInCareFlow) {
      safeRefetch(refetch, isVisitUnitialized, isVisitLoaded, isVisitError);
    }
  }, [isVisitError, isVisitUnitialized, isVisitLoaded]);

  const { data: member } = useGetMemberWithUsernameQuery(
    {
      username: visit?.patient_id,
      linked_entities: [MemberLinkedEntitiesEnum.METADATA]
    },
    { skip: visit === undefined }
  );

  useInterval(() => {
    if (isFalsy(visit)) return;
    const time = getElapsedTime(
      DateTime.fromISO(visit?.created_at).setZone(user?.user?.timezone)
    );

    setElapsedTime(time.toFormat(getTimeFormat(time)));
  }, 1000);

  const handleResumeClick = () => {
    StorageHelper.getItem(StorageEnum.LAST_SUBSECTION_CURRENT_VISIT).then(
      (result) =>
        isFalsy(result)
          ? navigate(`/visits/${visit?.visit_id}`)
          : navigate(`/visits/${visit?.visit_id}?current_subsection=${result}`)
    );
  };

  const isUserBusy = [
    UserPrecenseStatusEnum.LUNCH,
    UserPrecenseStatusEnum.BREAK,
    UserPrecenseStatusEnum.SCHEDULED_MEETING
  ].includes(user?.presence?.status);

  if (!isUserBusy && (visit === undefined || isLoading || isInCurrentVisit))
    return;

  return (
    <>
      <VisitContainer isUserBusy={isUserBusy} showSidebar={showSidebar}>
        <Box display={"flex"} alignItems={"center"} gap={1}>
          {elapsedTime && (
            <>
              <Typography variant="h6" color={"white"}>
                Timer
              </Typography>
              <Typography variant="body1" color={"white"}>
                {elapsedTime}
              </Typography>
            </>
          )}
        </Box>
        {!isUserBusy && member && (
          <Typography variant="h6" color={"white"}>
            Task in Progress for {getNameOrUsername(member?.patient)}
          </Typography>
        )}
        {isUserBusy && (
          <Typography variant="h6" color={"white"}>
            Away for {prettyStatusString(user?.presence?.status)}
          </Typography>
        )}
        {!isUserBusy && (
          <Flexbox flexDirection={"row"} gap={"10px"}>
            <VoiceCall member={member} isInBanner={true} />
            <WhiteButton onClick={handleResumeClick} disableElevation={false}>
              Resume
            </WhiteButton>
          </Flexbox>
        )}
        {isUserBusy && (
          <WhiteButton onClick={deleteUserPrecenseStatus}>
            Clear Status
          </WhiteButton>
        )}
      </VisitContainer>
      <Flexbox sx={{ marginBottom: "60px" }}></Flexbox>
    </>
  );
};

export default VisitInProgress;
