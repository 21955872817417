import {
  AllNursesIcon,
  AllProvidersIcon,
  TodoIcon
} from "../../assets/images/icons";
import {
  ALL_MEMBERS,
  ASSIGNED_MEMBERS,
  AWAITING_PROVIDER,
  COMPLETED_TODOS,
  INACTIVE_MEMBERS,
  MEMBER_CHART,
  NURSE_LIST,
  ORDER_DETAILS,
  MEMBERS_WITH_NO_ACTIVITY,
  REPORT_DETAILS,
  REPORT_DETAILS_BY_MEMBER,
  REPORT_LIST,
  STAFF_DETAILS,
  MY_TODOS,
  COMMON_ROUTES,
  PROVIDER_SCHEDULES,
  PROVIDER_LIST,
  PROVIDER_CALENDARING_ONLY,
  VISITS,
  PROVIDER_ENROLLMENT
} from "../RouteComponents";

import {
  SIDEBAR_ALL_MEMBERS,
  SIDEBAR_DASHBOARD,
  SIDEBAR_INACTIVE_MEMBERS,
  SIDEBAR_ORDERS,
  SIDEBAR_SUPPORT,
  SIDEBAR_REPORTS,
  SIDEBAR_SECTIONS,
  PROVIDER_SCHEDULES_SIDEBAR
} from "../RouteSidebar";
import RolesEnum from "common/enums/RolesEnum";

const sidebar = () => {
  const sidebarItems = [
    SIDEBAR_DASHBOARD(RolesEnum.RCM_ADMIN),
    {
      sectionName: SIDEBAR_SECTIONS.nurses,
      items: [
        {
          itemName: "All Nurses",
          itemLink: Routes.NURSES,
          ItemIcon: AllNursesIcon
        },
        { itemName: "To Do", itemLink: Routes.TASKS, ItemIcon: TodoIcon }
      ]
    }
  ];

  sidebarItems.push({
    sectionName: SIDEBAR_SECTIONS.providers,
    items: [
      {
        itemName: "All Providers",
        itemLink: Routes.PROVIDERS,
        ItemIcon: AllProvidersIcon
      },
      PROVIDER_SCHEDULES_SIDEBAR
    ]
  });

  sidebarItems.push({
    sectionName: SIDEBAR_SECTIONS.members,
    items: [SIDEBAR_ALL_MEMBERS, SIDEBAR_INACTIVE_MEMBERS, SIDEBAR_ORDERS]
  });
  sidebarItems.push(SIDEBAR_REPORTS);
  sidebarItems.push(SIDEBAR_SUPPORT);

  return sidebarItems;
};

import StaffDetailsRedirect from "../../pages/AllUserList/StaffDetails/StaffDetailsRedirect";
import OrderDevices from "../../pages/OrderDevices/OrderDevices";
import { RegisterPatient } from "../../pages/RegisterPatient";
import Routes from "../Routes";
import FeatureFlags from "common/config/FeatureFlags";

const RCM_ADMIN = {
  routes: [
    ...COMMON_ROUTES,
    {
      path: Routes.ROOT,
      components: ALL_MEMBERS({})
    },

    {
      path: Routes.NURSES,
      components: NURSE_LIST({})
    },
    {
      path: Routes.NURSE_REDIRECT,
      components: <StaffDetailsRedirect />
    },
    STAFF_DETAILS,
    {
      path: Routes.ALL_MEMBERS,
      components: ALL_MEMBERS({})
    },
    {
      path: Routes.AWAITING_PROVIDER,
      components: AWAITING_PROVIDER({
        tableColumns: [
          { name: "name" },
          { name: "phone" },
          { name: "birthdate" },
          { name: "nurseAssigned" }
        ]
      })
    },
    { path: Routes.INACTIVE_MEMBERS, components: INACTIVE_MEMBERS(true) },
    ...MEMBER_CHART(RolesEnum.RCM_ADMIN),
    {
      path: Routes.ASSIGNED_MEMBERS(":nurseId"),
      components: [MEMBERS_WITH_NO_ACTIVITY({}), ASSIGNED_MEMBERS({})]
    },
    {
      path: Routes.ORDERS,
      components: <OrderDevices />
    },
    {
      path: Routes.DEVICES_BY_MEMBER,
      components: <OrderDevices />
    },
    ORDER_DETAILS(),
    STAFF_DETAILS,
    {
      path: Routes.REGISTER_MEMBER,
      components: <RegisterPatient />
    },

    REPORT_LIST,
    REPORT_DETAILS,
    REPORT_DETAILS_BY_MEMBER,
    COMPLETED_TODOS,
    MY_TODOS,
    {
      path: Routes.PROVIDER_SCHEDULES,
      components: [PROVIDER_SCHEDULES({})]
    },
    {
      path: Routes.PROVIDERS,
      components: [
        PROVIDER_LIST({
          filterByAuthenticatedUser: false
        })
      ]
    },
    ...PROVIDER_CALENDARING_ONLY,
    VISITS,
    FeatureFlags().ONBOARDING_REVAMP_ENABLED && PROVIDER_ENROLLMENT
  ],
  sidebar: sidebar()
};

export default RCM_ADMIN;
